const working = process.env.REACT_APP_CLIENT_KEY || "zervx-development"; // localhost ,zervx-development,starmov
console.log(process.env);
let host;
switch (working) {
  case "starmov":
    host = "https://api.appstarmov.com";
    break;
  case "pamworld":
    host = "https://api1.pamworld.com";
    break;
  case "zervx-development":
    host = "https://api.zervx.com";
    break;
  case "demo":
    host = "https://api.demo.zervx.com";
    break;
  case "twende":
    host = "https://api.twendetech.co.tz";
    break;
  case "olympiahld":
    host = "https://api.wdy.wdy247.co.za";
    break;
  case "digito":
    host = "https://api.digito.co.in";
    break;
  case "jetridenew":
  case "jetride":
    host = "https://api2.jetrideapp.com";
    break;
  case "zayride":
    host = "https://api.fastrack.site";
    break;
  case "wdy":
    host = "https://api.wdy.wdy247.co.za";
    break;
  case "cabzone":
    host = "https://api.cabzone.co.uk";
    break;
  case "taximobility":
    host = "https://api.taximobility.co.il";
    break;
  case "actcanada":
    host = "https://api.thedailydosesociety.org";
    break;
  case "flixmobi":
    host = "https://api.flixmobi.net";
    break;
  case "adeegso":
    host = "https://api.adeegso.zervx.com";
    break;
  case "veedo":
    host = "https://api.veedo.co.za";
    break;
  case "wayder":
      host = "https://api.wayder.co.uk";
      break;
  case "rusha":
    host = "https://api.rushaapp.com";
    break;
  case "reydominica":
    host = "https://api.rey.com.do";
    break;
  default:
    host = "http://localhost:8080";
    break;
}

const urls = {
  MODE: working,
  HOST: host,
  REACT_CONFIG: "/api/admin/reactconfig",
  ADMIN_LANGUAGE_KEYS: "/api/admin/getLanguageKeys",
  TRACK: "/api/booking/track",
  TRACK_CURRENT_LOCATION: "/api/booking/track/location",
};
module.exports = urls;
